import colors from '@assets/colors';
import Icon from '@assets/svg';
import { Button04 } from '@src/components/button';
import { useAppSelector } from '@src/ducks/ducksHook';
import { useSignsecureService } from '@src/ducks/hooks';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';
import { selectedCreateRequestLoading, selectedDocumentParties, selectedEnableSignature, selectedSignSecureLoadingStatus, selectedViewDocument } from '@src/ducks/slices/signsecure.slice';
import { LiveRequestNotarySteps, LiveRequestSteps } from '@src/utils/mockdata';
import useSVGSizeHelper from '@src/utils/svg-size-helper';
import { disableAffixButton } from '@src/utils/validation-helper';
import downloadjs from 'downloadjs';
import moment from 'moment';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useHeaderContext } from '../../provider/header';
import { VideoContext } from '../../video-session/provider/video';
import Items from './items';
import useStyles from './styles.css';
import { useDocumentContext } from '../../provider/document';
// import { DigitalNotarialBookContext } from "../../digital-notarial-book/provider";

const getButtonLabel = (active: number) => {
  switch(active){
  case 1:
    return "Update Notarial Book"
  case 7:
    return "Download";
  case 6:
    return "Submit to Notary";
  default:
    return "Affix Signature";
  }
};

const StepsHeader = () => {
  const styles = useStyles();
  const totalSteps = LiveRequestSteps.length;
  const [fullwidth, setFullWidth] = React.useState(809);
  const document = useAppSelector(selectedViewDocument);
  const { signDocumentRequest } = useSignsecureService();
  const userParty = useAppSelector(selectedDocumentParties);
  const loading = useAppSelector(selectedCreateRequestLoading)
  const enableSignature = useAppSelector(selectedEnableSignature);
  const loadingLabel = useAppSelector(selectedSignSecureLoadingStatus);
  const { activeIndex, onBack, onSetCurrentPage } = useHeaderContext();
  const ButtonLabel = React.useMemo(() => getButtonLabel(activeIndex), [activeIndex]);
  const disabled = React.useMemo(() => disableAffixButton(document, userParty, activeIndex), [document, userParty, activeIndex]);
  const indicatorFill = `${(activeIndex / totalSteps) * 100}%`;
  const indicatorOutline = `${(totalSteps / totalSteps) * 100}%`;
  const role = useAppSelector(selectedAuthRole);
  const { isJoined } = React.useContext(VideoContext);
  const { setEnablePayment } = useDocumentContext();
  // const {
  //   updateNotarialBook
  // } = React.useContext(DigitalNotarialBookContext);

  const onItemClick = React.useCallback((value: number) => {
    onSetCurrentPage(value);
  }, []);

  const onPress = React.useCallback(async() => {
    switch(activeIndex){
    case 0:
      signDocumentRequest();
      break;
    case 6:
      setEnablePayment(true);
      // signDocumentRequest();
      break;
    case 7:
      downloadFile();
      break;
    }
  },[activeIndex]);

  const downloadFile = React.useCallback(() => {
    downloadjs(
      document?.uri,
      `notary-signed-${moment().format('YYYY-MM-DD hh:mm:ss A')}.pdf`,
      'application/pdf'
    );
  }, [document?.uri]);

  const backSize = useSVGSizeHelper({
    "LARGE": { width: 9, height: 14},
    "DEFAULT": { width: 12, height: 18},
  });

  // let steps = role === 'principal' ? LiveRequestSteps : LiveRequestNotarySteps;
  // let disableSteps = role === 'principal' ? [] : ['Review'];

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => onBack()}
        activeOpacity={0.9}
        style={styles.btnIcon}
      >
        <Icon name="back" fill={colors.purple4} {...backSize} />
      </TouchableOpacity>
      <View onLayout={(e) => setFullWidth(e.nativeEvent.layout.width)} style={styles.steps_container}>
        <View style={styles.v_line_wrapper}>
          <View style={[styles.steps_outline, { width: indicatorOutline,  }]} />
          <View style={[styles.steps_fillLine, { width: indicatorFill,  }]} />
        </View>
        {LiveRequestSteps.map((item, index) => {
          return (
            <Items
              // disableSteps={disableSteps}
              key={`stepIdx${index}`}
              item={item}
              index={index}
              itemSize={fullwidth / LiveRequestSteps.length}
              onPress={onItemClick}
              isActive={activeIndex >= index}
            />
          );
        })}
      </View>
      {[6].includes(activeIndex) && 
        <Button04
          // disabled={disabled}
          onPress={onPress}
          isLoading={loading}
          style={styles.btn_affix}
          labelStyle={styles.lbl_affix}
          label={loading ? loadingLabel : ButtonLabel}
        />}
    </View>
  );
};

export default React.memo(StepsHeader);
