import { API_BASE_URL, WEB_BASE_URL } from "@env";
import { useAuthService } from "@src/ducks/hooks";
import { largeSize, mobileSize, tabletSize } from "@src/utils/screensize-helper";
import * as faceapi from "face-api.js";
import React, { useCallback, useEffect, useRef } from "react";
import { View, useWindowDimensions } from "react-native";
import FaceDetectionCamera from "screens/drawer-screens/account/face-id-register/FaceDetectionCamera.web";
import useStyles from "./styles.css";

const EYE_BLINK_THRESHOLD = 0.1; // The distance between the inner and outer corners of the eye when the eye is open

type Props = {
  isLandScape: boolean;
  onScanning: () => void;
  onScanFailed: () => void;
  onScanCompleted: () => void;
  onProgress : (value : number) => void;
}

const FaceRecognition = (props: Props) => {
  const styles = useStyles();
  const isLarge = largeSize();
  const isTablet = tabletSize();
  const isMobile = mobileSize();
  const timer = useRef<any>(null);
  const videoRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);
  const { width, height } = useWindowDimensions();
  const lastLandmarks = useRef<faceapi.FaceLandmarks68 | null>(null);

  const { loginInput } = useAuthService();
  const { videoHeight, videoWidth, cameraWidth, cameraHeight } = React.useMemo(() => {
    if(isMobile){
      return {
        videoWidth: width,
        videoHeight: 651,

        cameraWidth: 1024,
        cameraHeight: 576,
      };
    }

    if(isLarge){
      return {
        videoWidth: 380,
        videoHeight: 450,
        cameraWidth: 380,
        cameraHeight: 450,
      };
    }else if(props.isLandScape){
      return {
        videoWidth: 450,
        videoHeight: 550,
        cameraWidth: 450,
        cameraHeight: 550,
      };
    }else if(isTablet){
      return {
        videoWidth: 600,
        videoHeight: 790,
        cameraWidth: 1024,
        cameraHeight: 576,
      };
    }
    return {
      videoWidth: 450,
      videoHeight: 550,
      cameraWidth: 600,
      cameraHeight: 790,
    };
  },[width, isLarge, isTablet, isMobile, props.isLandScape]);

  const objectImages : any = [];
  const getImage = () => {
    const requestOptions = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({email: loginInput.email})
    };
    fetch(`${API_BASE_URL}/auth/face-id`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data.success){
          const item = data.data;
          if(item.detections){
            console.log("detection",item);
            console.log("item.detections[0]",item.detections[0]);
            const detection = JSON.parse(item.detections[0]);
            objectImages.push(detection);
          }
        }
      });

  };

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia( { 
        audio: false,
        video:  { 
          facingMode: "user", 
          width: cameraWidth,
          height: cameraHeight,
        } 
      })
      .then(stream => {
        const video:any = videoRef.current;
        console.log("videoRef",videoRef);
        //check if video is not null
        if(video){
          video.srcObject = stream;
          video.play();
        }
      })
      .catch(err => {
        console.error("error:", err);
      });
      
  };

  const handleVideoOnPlay = useCallback(() => {
    timer.current = startTimer();
    return () => clearInterval(timer.current);
  }, []);

  const calculateEyeAspectRatio = (eye: faceapi.Point[]) => {
    const verticalDist1 = Math.sqrt(Math.pow(eye[1].x - eye[5].x, 2) + Math.pow(eye[1].y - eye[5].y, 2));
    const verticalDist2 = Math.sqrt(Math.pow(eye[2].x - eye[4].x, 2) + Math.pow(eye[2].y - eye[4].y, 2));
    const horizontalDist = Math.sqrt(Math.pow(eye[0].x - eye[3].x, 2) + Math.pow(eye[0].y - eye[3].y, 2));
    const eyeAspectRatio = (verticalDist1 + verticalDist2) / (2 * horizontalDist);
    return eyeAspectRatio;
  };

  const isLive = (detections: faceapi.FaceDetection[]) => {
    if (detections.length === 0) {
      return false; // No faces detected
    }
  
    // Assuming only one face is detected for simplicity
    const faceLandmarks = detections[0].landmarks;
  
    // Calculate distances between key points over time
    const distances = calculateDistances(faceLandmarks);
  
    // Analyze changes in distances to determine liveness
    const movementThreshold = 7; // Adjust as needed
    const isMoving = distances.some(distance => distance > movementThreshold);

    const leftEye = faceLandmarks.getLeftEye();
    const rightEye = faceLandmarks.getRightEye();

    // Calculate the aspect ratio of each eye
    const leftEyeAspectRatio = calculateEyeAspectRatio(leftEye);
    const rightEyeAspectRatio = calculateEyeAspectRatio(rightEye);

    // Threshold to determine if a blink is occurring
    const blinkThreshold = 0.27; // Adjust as needed
    // Check if the aspect ratio of both eyes is below the threshold
    const isBlink = leftEyeAspectRatio < blinkThreshold && rightEyeAspectRatio < blinkThreshold;
    return  isBlink;//|| isMoving;
  };
  
  const calculateDistances = (landmarks: faceapi.FaceLandmarks68) => {
    const keyPoints = landmarks.positions;
    const distances = [];
  
    for (let i = 0; i < keyPoints.length - 1; i++) {
      for (let j = i + 1; j < keyPoints.length; j++) {
        const dx = keyPoints[i].x - keyPoints[j].x;
        const dy = keyPoints[i].y - keyPoints[j].y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        distances.push(distance);
      }
    }
  
    return distances;
  };

  const detectExpressionChange = (prevLandmarks: faceapi.Point[], currentLandmarks: faceapi.Point[]) => {
    if (!prevLandmarks || prevLandmarks.length === 0 || !currentLandmarks || currentLandmarks.length === 0) {
      return false;//'No previous or current landmarks available';
    }
  
    // Calculate the Euclidean distance between corresponding points in previous and current frames
    const distances = [];
    for (let i = 0; i < prevLandmarks.length; i++) {
      const dx = prevLandmarks[i].x - currentLandmarks[i].x;
      const dy = prevLandmarks[i].y - currentLandmarks[i].y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      distances.push(distance);
    }
  
    // Define a threshold to determine significant changes in expression
    const expressionChangeThreshold = 5; // Adjust as needed
  
    // Check if any distance exceeds the threshold
    const isExpressionChange = distances.some(distance => distance > expressionChangeThreshold);
  
    return isExpressionChange ? true : false;
  };
  
  const updateProgress = (matches :any) => {
    let newProgress = 0;
    if (matches >= 3) {
      newProgress = 100;
    } else {
      newProgress = (matches / 3) * 100; // Assuming you want a progress bar with 3 steps
    }
    props.onProgress(newProgress);
  };

  const startTimer = () => {
    let matches = 0;
    let distanceCameraType = 0;
    const eyeBlinking = false;
    let notDetectedCtr = 0;
    const headMoving = false;
    
    return setInterval(async () => {
      // ==============================================================
      const video:any = videoRef.current;
      if (faceapi.nets.ssdMobilenetv1.isLoaded &&
              faceapi.nets.faceLandmark68Net.isLoaded &&
                faceapi.nets.faceRecognitionNet.isLoaded) {
        const labeledFaceDescriptors = await Promise.all(objectImages[0].map(async(data : any) => {
          console.log("descriptior",data);
          const faceDescriptors = new Float32Array(Object.values(data.descriptor));
          console.log("faceDescriptors",faceDescriptors);
          const faces = [faceDescriptors];
          return new faceapi.LabeledFaceDescriptors(loginInput.email,faces) ;
        })
        );
      
        // Create a face detection options object with a minimum confidence threshold
        const detectionOptions = new faceapi.SsdMobilenetv1Options({ minConfidence: 0.85 });
        // console.log("labeledFaceDescriptors --", labeledFaceDescriptors);
        const detections = await faceapi.detectAllFaces(video, detectionOptions).withFaceLandmarks().withFaceDescriptors();
        if (detections.length > 0 ) {
          console.log("detections-->",detections);   
          const maxDescriptorDistance = 0.6;
          if(isLive(detections) || eyeBlinking){
            console.log("labeledFaceDescriptors",labeledFaceDescriptors);
            if(labeledFaceDescriptors.length > 0){

              const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors, maxDescriptorDistance);
              const results = detections.map(fd => faceMatcher.findBestMatch(fd.descriptor));
              results.forEach((bestMatch, i) => {
                const box = detections[i].detection.box;
                const text = bestMatch.label;
                      
                console.log(`box.area: ${matches}`, box.area);
                if ((!text.includes("unknown") && detections[i].alignedRect.score > 0.90 &&
                     (box.area / 1000) > 40  && (box.area / 1000) < 95)) {
                  // const drawBox = new faceapi.draw.DrawBox(box, { label: text });
                  // drawBox.draw(canvasRef.current);
                                      
                  console.log("alignedRect.score:  " + detections[i].alignedRect.score);
                  console.log("box.area:  " + box.area);
                  console.log("box.bottom:  ", bestMatch.distance);
                  
                  if ((box.area / 1000) > 40  && (box.area / 1000) < 50) {
                    if (distanceCameraType != 1) matches = 0;
                    matches += 1;
                    distanceCameraType = 1;
                  }
                  else if ((box.area / 1000) > 50  && (box.area / 1000) < 60) {
                    if (distanceCameraType != 2) matches = 0;
                    matches += 1;
                    distanceCameraType = 2;
                  }
                  else if ((box.area / 1000) > 60  && (box.area / 1000) < 70) {
                    if (distanceCameraType != 3) matches = 0;
                    matches += 1;
                    distanceCameraType = 3;
                  }
                  else if ((box.area / 1000) > 70  && (box.area / 1000) < 80) {   
                    if (distanceCameraType != 4) matches = 0;
                    matches += 1;
                    distanceCameraType = 4;
                  }
                  else if ((box.area / 1000) > 80  && (box.area / 1000) < 95) {  
                    if (distanceCameraType != 5) matches = 0;
                    matches += 1;
                    distanceCameraType = 5;
                  }
                  else {
                    matches = 0;
                    distanceCameraType = 0;
                  }
                  updateProgress(matches);
                  if (3 >= matches) {
                    stopVideo();
                    props.onScanCompleted();
                  }
                }
                else {
                  matches = 0;
                  distanceCameraType = 0;
                }
              });
            }
          }
        } else {

          //wait the camera to became available 
          if(videoRef.current){
            notDetectedCtr += 1;
          }
          if(notDetectedCtr >= 3){
            stopVideo(props.onScanFailed());
            notDetectedCtr = 0;
          }
          console.log("No face detected ctr-", notDetectedCtr);
        }
      }
    }, 100);
  };

  const stopVideo = (arg1: any = null) => {
    timer.current && clearInterval(timer.current);
    if(videoRef.current){
      videoRef.current.pause();
      typeof arg1 === "function" && arg1();
      if (videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track :any) => track.stop());
      }
    }
  };

  const loadModels = async () => {
    const MODEL_URL = `${WEB_BASE_URL}/models/`;
    Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    ]).then(result => {
      console.log("Successfully loaded..");
      props.onScanning();
    })
      .catch(error => {
        console.log("Still error and no luck: ", JSON.stringify(error));
      });
  };

  useEffect(() => {
    loadModels();
    getImage();
    startVideo();
    return () => stopVideo();
  }, []);

  return (
    <View style={[styles.v_camera_view, { width: videoWidth, height: videoHeight}]}>
      <FaceDetectionCamera 
        canvasRef={canvasRef} 
        cameraRef={videoRef}
        height={videoHeight}
        width={videoWidth}
        cameraOnPlay={handleVideoOnPlay} />
    </View>
  );
};

export default FaceRecognition;
