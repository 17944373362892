import React, { createContext, useRef, useCallback, useState } from 'react';
import colors from '@assets/colors';
import {
  selectUserEmail,
  // selectUserFullname,
} from '@src/ducks/slices/user.slice';
import { useAppSelector } from '@src/ducks/ducksHook';
// import { selectedViewDocument } from '@src/ducks/slices/signsecure.slice';
import * as Types from './types';
import { useFetchGet } from '@src/utils/api/hooks';
import _ from 'lodash';
import { selectedViewDocument } from '@src/ducks/slices/signsecure.slice';
import {
  // selectedAuthUserEmail,
  selectedAuthUserName,
  selectedAuthRole
} from '@src/ducks/slices/auth.slice';
import { HeaderContext } from '../../provider/header';

interface ContextValue {
  apiRef: any;
  jwt: string,
  participants: object[];
  handleParticipantChange: () => void;
  handleIncomingMessage: (value: any) => void;
  handleIFrameRef: (value: any) => void;
  handleVideoConferenceJoined: (value: any) => void;
  handleVideoConferenceLeft: (value: any) => void;
  messages: any[];
  sendMessage: () => void;
  currentMessage: string;
  setCurrentMessage: any,
  handleConferenceCreatedTimestamp: (value: any) => void;
  timer: any,
  handToggleShareScreen: () => void;
  handleStartRecording: () => void;
  handleHangup: () => void;
  handleToggleAudio: () => void;
  // jitsiRef: any;
  // recordingTimer: string,
  isJoined: boolean;
  mic: boolean;
  // video: boolean;
  // tileview: boolean;
  // showsignsecure: boolean;
  // onChangeSignSecure: () => void;
  onChangeMicStatus: (value: boolean) => void;
  // onChangeVideoStatus: (value: boolean) => void;
  // onChangeTileViewStatus: (value: boolean) => void;
  // updateParticipants: (value: any) => void;
  // initializeMessages: () => void;
  // handleChatMessages: (payload: any) => void;
  // sendMessage: (message: string, participantId?: string) => void;
  // stopRecording: () => void;
  endSessionDialog: boolean;
  setEndSessionDialog: any;
}

// export const FONT_SIZE = 15;
// export const IMAGE_SIZE = 20;
// export const LAYER_WIDTH = 130;
// export const LAYER_HEIGHT = 50;

const VideoContext = createContext<ContextValue>({} as ContextValue);

const VideoProvider = ({ children }: Types.DocumentProviderProps) => {
  const jitsiRef = useRef<any>();
  const [messages, setMessages] = useState<any>([]);
  const [currentMessage, setCurrentMessage] = useState('');
  // const jitsiRef = useRef<any>();
  // // const document = useAppSelector(selectedViewDocument);
  const [mic, setMic] = useState(false);
  // const [video, setVideo] = useState(false);
  // const [tileview, setTileView] = useState(false);
  // const fullname = useAppSelector(selectUserFullname);
  // const [showsignsecure, setSignsecure] = useState(false);
  // const [recordingTimer, setRecordingTimer] = useState("00:00:00")
  const [isJoined, setIsJoined] = useState(false)

  const [jwt, setJwt] = useState('');
  const { loading, data, runRequest } = useFetchGet();
  const role = useAppSelector(selectedAuthRole);
  const email = useAppSelector(selectUserEmail);
  const displayName = useAppSelector(selectedAuthUserName);
  const document = useAppSelector(selectedViewDocument);
  const [participants, setParticipants] = useState<any>([]);
  const [conferenceStartTime, setConferenceStartTime] = useState<number | null>(null);
  const [timer, setTimer] = useState<string>('00:00:00');

  const [endSessionDialog, setEndSessionDialog] = useState(false)
  const { onSetCurrentPage } = React.useContext(HeaderContext);

  const handleFetchJwt = React.useCallback(() => {
    const API = `/jitsi-jwt/generate?name=${displayName}&email=${email}&moderator=${role === 'notary' ? 'true' : 'false'}&room=${document.id}`;
    runRequest(API);
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(data)) {
      setJwt(data.data.token)
    }
  }, [data]);

  React.useEffect(() => {
    handleFetchJwt();
  }, [handleFetchJwt]);
  

  React.useEffect(() => {
    if (conferenceStartTime) {
      const intervalId = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - conferenceStartTime;

        const hours = Math.floor(elapsedTime / 3600000);
        const minutes = Math.floor((elapsedTime % 3600000) / 60000);
        const seconds = Math.floor((elapsedTime % 60000) / 1000);

        setTimer(
          `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
        );
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [conferenceStartTime]);

  
  const handleIncomingMessage = useCallback((message: string) => {
    setMessages((prevMessages: any) => [...prevMessages, message]);
  });
  

  const sendMessage = useCallback(() => {
    if (jitsiRef.current && currentMessage.trim()) {
      jitsiRef.current.executeCommand('sendChatMessage', currentMessage);
      setMessages((prevMessages: any) => [
        ...prevMessages,
        {
          message: currentMessage,
          nick: displayName,
        }
      ]);

      setCurrentMessage('');
    }
  });

  const handleConferenceCreatedTimestamp = useCallback((payload: any) => {
    setConferenceStartTime(payload.timestamp);
  }, []);


  const setUniqueParticipants = useCallback(() => {
    const participants = jitsiRef.current.getParticipantsInfo();

    const uniqueParticipantsMap = new Map();
  
    participants.forEach((participant: any) => {
      uniqueParticipantsMap.set(participant.displayName, participant);
    });
  
    const uniqueParticipants = Array.from(uniqueParticipantsMap.values());
  
    setParticipants(uniqueParticipants);
  });

  // const handleKickParticipant = useCallback((participant: any) => {
  //   if (jitsiRef.current) {
  //     const participants = jitsiRef.current.getParticipantsInfo();
  //     const duplicateParticipants = participants.filter(
  //       (p: any) => p.displayName === participant.displayName
  //     );
  //     console.log('participants23232', participants)
  //     if (duplicateParticipants.length >= 2) {
  //       jitsiRef.current.executeCommand('kickParticipant', participant.id);
  //     }
  //   }
  // });

  const handToggleShareScreen = useCallback((participant: any) => {
    if (jitsiRef.current) {
      if (role === 'notary') {
        jitsiRef.current?.executeCommand('toggleShareScreen');
      }
    }
  });

  const handleStartRecording = useCallback((payload: any) => {
    if (role === 'notary') {
      jitsiRef.current?.executeCommand('startRecording', { mode: "file" });
    }
  });


  const handleHangup = useCallback(() => {
    setConferenceStartTime(null);
    if (role === 'principal') {
      onSetCurrentPage(7);
    } else {
      jitsiRef.current?.executeCommand('stopRecording', "file");
      onSetCurrentPage(2);
    }
    jitsiRef.current?.executeCommand('hangup');
  });

  const handleToggleAudio = useCallback(() => {
    jitsiRef.current?.executeCommand('toggleAudio')
  });
  
  const handleParticipantChange = useCallback((participant: any) => {
    if (jitsiRef.current) {
      setUniqueParticipants();
      // handleKickParticipant(participant);
    }
  }, [participants]);

  const addParticipantToArray = useCallback((newParticipant: any) => {
    setParticipants((prevParticipants: any[]) => {
      const filteredParticipants = prevParticipants.filter(
        (participant) => participant.displayName !== newParticipant.displayName
      );
  
      // Add the new participant to the filtered list
      return [...filteredParticipants, newParticipant];
    });
  });

  const handleVideoConferenceJoined = useCallback((participant: any) => {
    if (jitsiRef.current) {
      setIsJoined(true)
      addParticipantToArray(participant);
    }
  }, [participants]);

  const handleVideoConferenceLeft = useCallback((participant: any) => {
    if (jitsiRef.current) {
      setIsJoined(false)
    }
  }, [participants]);

  const handleIFrameRef = useCallback((iframeRef: any) => {
    console.log('iframeRefiframeRef', iframeRef)
    iframeRef.style.backgroundColor = colors.black8;
    iframeRef.style.width = '100%';
    iframeRef.style.height = '100%';
    // iframeRef.style.pointerEvents = 'none';
  });
  

  // const onChangeSignSecure = useCallback(() => {
  //   setSignsecure(!showsignsecure);
  // }, [showsignsecure]);

  const onChangeMicStatus = useCallback(
    (value: boolean) => {
      setMic(value);
    },
    [mic]
  );

  // const onChangeVideoStatus = useCallback(
  //   (value: boolean) => {
  //     setVideo(value);
  //   },
  //   [video]
  // );

  // const onChangeTileViewStatus = useCallback(
  //   (value: boolean) => {
  //     setTileView(value);
  //   },
  //   [tileview]
  // );

  // const updateParticipants = useCallback(() => {
  //   // if (jitsiRef && jitsiRef.current) {
  //     const myUserId = jitsiRef.current?._myUserID
  //     const result = jitsiRef.current?.getParticipantsInfo();
  //     const listOfParticipants = [...result].filter((item: any) => item.participantId !== "local")
  //     setParticipants(listOfParticipants);

  //     if (listOfParticipants.length > 1 && !isRecording) {
  //       const host = listOfParticipants[0]
  //       if (host && host.participantId === myUserId) {
  //         isRecording = true
  //         jitsiRef.current?.executeCommand('toggleShareScreen');
  //         jitsiRef.current?.on('screenSharingStatusChanged', (payload: any) => {
  //           if (payload?.on) {
  //             jitsiRef.current?.executeCommand('startRecording', {
  //               mode: "file",
  //             });
  //             jitsiRef.current?.on('recordingStatusChanged', (payload: any) => {

  //                 const recordingType = payload?.on ? 'startRecording' : 'stopRecording'
  //                 const participantId = myUserId
  //                 const messageObject = JSON.stringify({
  //                   type: recordingType,
  //                   id: participantId,
  //                   expire: Date.now()
  //                 })
  //                 jitsiRef.current.executeCommand('sendChatMessage', messageObject);
  //             });
  //           }
  //         });
  //       }
  //     }
  //   // }
  // }, [participants]);

  // const stopRecording = useCallback(() => {
  //   const myUserId = jitsiRef.current?._myUserID
  //   const messageObject = JSON.stringify({
  //     type: 'stopRecording',
  //     id: myUserId,
  //     expire: Date.now()
  //   })
  //   jitsiRef.current.executeCommand('sendChatMessage', messageObject);
  // }, [])

  // const handleChatMessages = useCallback((payload: any) => {
  //   let timerChannel: any = null
  //   const messageObject = JSON.parse(payload.message)
  //   if (userInfo) {
  //     if (messageObject.type === 'message') {
  //       const yourMessage = messageObject.id === userInfo.participantId
  //       setMessages((messages: any[]) => [...messages, { ...messageObject, yourMessage }])
  //     }
  //     else if (messageObject.type === "initializeFields") {
  //       const myUserId = jitsiRef.current._myUserID
  //       userInfo = { participantId: myUserId }
  //       setIsJoined(true)
  //       updateParticipants()
  //     }
  //     else if (messageObject.type === "startRecording") {
  //       const delay = 1000
  //       const tick = 1000
  //       setTimeout(() => {
  //         timerChannel = setInterval(() => {
  //           const timerTick = Math.floor(((Date.now() - delay) - messageObject.expire) / tick)
  //           let everyMinute = 60
  //           let minutes = timerTick / everyMinute
  //           let seconds = (timerTick % 60)

  //           if (Math.floor(minutes) === 0) {
  //             setRecordingTimer(`00:00:${seconds < 10 ? `0${seconds}` : seconds}`)
  //           }
  //           else if (Math.floor(minutes) >= 59) {
  //             const secondsString = `${seconds < 10 ? `0${seconds}` : seconds}`
  //             const minutesString = `${minutes < 10 ? `0${minutes}` : minutes}`
  //             setRecordingTimer(`00:${minutesString}:${secondsString}`)
  //             // stopRecording()
  //             clearInterval(timerChannel)
  //             jitsiRef.current?.executeCommand('stopRecording', "file");
  //           }
  //           else {
  //             setRecordingTimer(`00:0${Math.floor(minutes)}:${seconds < 10 ? `0${seconds}` : seconds}`)
  //           }
            
  //         }, tick)

  //       }, delay-tick);
  //     }
  //     else if (messageObject.type === "stopRecording") {
  //       if (timerChannel) {
  //         let shouldStopRecording = true
  //         const myUserId = jitsiRef.current._myUserID
  //         const result = jitsiRef.current?.getParticipantsInfo();
  //         const listOfParticipants = [...result].filter((item: any) => item.participantId !== "local")
  //         if (listOfParticipants.length > 0 && isRecording) {
  //           const host = listOfParticipants[0]
  //           if (host && host.participantId !== myUserId) {
  //             shouldStopRecording = false
  //           }
  //         }
  //         if (shouldStopRecording) {
  //           jitsiRef.current.on('recordingLinkAvailable', (payload: any) =>{
  //             alert(JSON.stringify(payload))
  //           });
  //           jitsiRef.current?.executeCommand('stopRecording', "file");
  //         }
  //         clearInterval(timerChannel)
  //       }
  //     }
  //     else if (messageObject.type === "affixSignature") {
  //     }
  //   }
  // }, [jitsiRef, setIsJoined])

  // const sendMessage = useCallback((message: string, participantId: string = "") => {
  //   if (jitsiRef && jitsiRef.current) {
  //     const myUserId = jitsiRef.current._myUserID
  //     const result = jitsiRef.current?.getParticipantsInfo();
  //     const participant = [...result].find((item: any) => item.participantId === myUserId)
  //     if (participant) {
  //       const messageObject = JSON.stringify({
  //         type: 'message',
  //         id: participant.participantId,
  //         email: participant.email ?? "",
  //         name: participant.displayName,
  //         text: message,
  //       })
  //       jitsiRef.current.executeCommand('sendChatMessage', messageObject, participantId);
  //     }
  //   }
  // }, [jitsiRef])

  // const initializeMessages = useCallback(() => {
  //   if (!isInitialized) {
  //     if (jitsiRef && jitsiRef.current) {
  //       isInitialized = true
  //       const participantId = jitsiRef.current._myUserID
  //       const messageObject = JSON.stringify({
  //         type: 'initializeFields',
  //         id: participantId,
  //       })
  //       userInfo = { participantId }
  //     jitsiRef.current.executeCommand('sendEndpointTextMessage', '', currentMessage);
  // //       jitsiRef.current.executeCommand('sendChatMessage', messageObject);
  //     }
  //   }
  // }, [jitsiRef])

  const value = {
    jitsiRef,
    jwt,
    participants,
    handleParticipantChange,
    handleIncomingMessage,
    handleIFrameRef,    
    messages,
    sendMessage,
    currentMessage,
    setCurrentMessage,
    handleConferenceCreatedTimestamp,
    handleVideoConferenceJoined,
    handleVideoConferenceLeft,
    timer,
    handToggleShareScreen,
    handleStartRecording,
    handleHangup,
    handleToggleAudio,
    isJoined,
    // recordingTimer,
    // isJoined,
    mic,
    // video,
    // tileview,
    // showsignsecure,
    onChangeMicStatus,
    // onChangeVideoStatus,
    // onChangeTileViewStatus,
    // updateParticipants,
    // onChangeSignSecure,
    // initializeMessages,
    // handleChatMessages,
    // sendMessage,
    // stopRecording,
    endSessionDialog,
    setEndSessionDialog
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export { VideoProvider, VideoContext };
