import React from 'react';
import { View } from 'react-native';
import { CanvasProvider } from './add-fields/provider/canvas';
import { PluginProvider } from './add-fields/provider/plugin';
import Header from './header';
import { DocumentProvider } from './provider/document';
import { HeaderProvider } from './provider/header';
import Screens from './screens';
import useStyles from './styles.css';
// import { DigitalNotarialBookProvider } from './digital-notarial-book/provider';

const LiveRequest = () => {
  const styles = useStyles();

  return (
    <HeaderProvider>
      <DocumentProvider>
        <PluginProvider>
          <CanvasProvider>
            {/* <DigitalNotarialBookProvider> */}
              <View style={styles.main}>
                <Header />
                <Screens />
                <View style={styles.v_height40} />
              </View>
            {/* </DigitalNotarialBookProvider> */}
          </CanvasProvider>
        </PluginProvider>
      </DocumentProvider>
    </HeaderProvider>
  );
};

export default LiveRequest;
