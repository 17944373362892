// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import { ErrorValue, SessionValue, loginInputValue, TypeValue } from '../types';
import type { RootState } from '@src/ducks/store';
import { createSelector } from '@reduxjs/toolkit';

export interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  session: SessionValue;
  error: ErrorValue;
  loginInput: loginInputValue;
}

export const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  session: {} as SessionValue,
  error: {} as ErrorValue,
  loginInput: {
    email: "",
    password: "",
    rememberme: false,
  } as loginInputValue,
};

// Slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginInput: (state, action) => {
      if(action.payload.type === "email"){
        state.loginInput.email = action.payload.value;
      }else if(action.payload.type === "password"){
        state.loginInput.password = action.payload.value;
      }else if(action.payload.type === "rememberme"){
        state.loginInput.rememberme = action.payload.value;
      }
    },
    loginRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    loginSuccess: (state, action) => {
      state.session = action.payload;
      state.error = {} as ErrorValue;
      state.isLoggedIn = true;
      state.loading = false;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    faceIDloginRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    
    resetLoading: (state) => {
      state.loading = false;
    },
    logout: (state) => {
      if(state.loginInput?.rememberme){
        state.isLoggedIn = false;
        state.loginInput.password = "";
        state.session = {} as SessionValue;
      }else{
        return initialState;
      }
    },
    refreshTokenUpdate: (state, action) => {
      state.session.accessToken = action.payload;
    },
  },
});

// Actions
export const authActions = {
  setLoginInput: createAction(
    `${authSlice.name}/setLoginInput`,
    (params: TypeValue) => ({
      payload: params,
    })
  ),
  
  loginRequest: authSlice.actions.loginRequest,
  loginSuccess: authSlice.actions.loginSuccess,
  loginFailure: authSlice.actions.loginFailure,

  faceIDloginRequest: createAction(`${authSlice.name}/faceIDloginRequest`),

  resetLoading: authSlice.actions.resetLoading,
  logout: authSlice.actions.logout,

  refreshTokenRequest: createAction(`${authSlice.name}/refreshTokenRequest`),
  refreshTokenUpdate: authSlice.actions.refreshTokenUpdate,
};

// Selectors
export const selectAuthLoggingIn = (state: RootState) => state.auth.loading;
export const selectAuthLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectAuthLogInFailed = (state: RootState) => state.auth.error;
export const selectAuthSession = (state: RootState) => state.auth.session;
export const selectAuthLoginInput = (state: RootState) => state.auth.loginInput;
export const selectedAuthLoginType = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.loginType ?? ""
);
export const selectedAuthAccessToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.accessToken ?? ''
);
export const selectedAuthRefresh = createSelector(
  (state: RootState) => state.auth.session,
  (session) => ({
    userId: session?.id ?? '',
    accessToken: session?.accessToken ?? '',
    refreshToken: session?.refreshToken ?? '',
  })
);
export const selectedAuthUserID = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.id ?? ''
);
export const selectedAuthUserEmail = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.email ?? ''
);
export const selectedAuthUserName = createSelector(
  (state: RootState) => state.auth.session,
  (user) =>`${user.firstName || ""} ${user.lastName || ""}`.trim()
);
export const selectedAuthRefreshToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.refreshToken
);
export const selectedAuthRole = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.role ?? 'principal'
);

// Reducer
export default authSlice.reducer;
