import { SagaIterator } from "@redux-saga/core";
import {
  DocumentFormDataParser,
} from "@src/utils/formdata-helper";
import { call, delay, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";

// HELPER
import {
  affixSignature4Contactless,
  completeSignatureWithCreateManifest,
  submitOnlyAffixSignature,
} from "@src/utils/signature-helper";

// API
import {
  addDigitalNotarialBook,
  createMayaCustomer,
  fetchNotaryDetails,
  generateMayaToken,
  getIpInfo,
  linkGeneratedMayaTokenToCustomer,
  paymayaTransaction,
  signsecureCompleteDocument,
  signsecureCreateDocument,
  signsecureDownloadDocument,
  signsecureSignDocument,
  signsecureViewDocument
} from "@src/utils/api";

// Slice
import { WEB_BASE_URL } from "@env";
import { checkPartiesSigned } from "@src/utils/filter-helper";
import _ from "lodash";
import moment from "moment";
import { authActions, selectedAuthAccessToken, selectedAuthLoginType, selectedAuthRole } from "../slices/auth.slice";
import { selectedViewDocument, signsecureActions } from "../slices/signsecure.slice";
import { selectUserDetails, userActions } from "../slices/user.slice";
import { CreateLiveRequestValue } from "../types";

function* handleCreateLiveRequest(action: {
  type: typeof signsecureActions.createLiveRequest,
  payload: CreateLiveRequestValue,
}): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    const body = yield call(DocumentFormDataParser, action.payload, ip);
    const document = yield call(signsecureCreateDocument, body, accessToken);
    console.log('documentdocument123', document)
    
    const base64 = yield call(
      signsecureDownloadDocument,
      document?.data?.id || document?.data?._id,
      accessToken
    );

    yield put(signsecureActions.viewDocumentSuccess({...document.data, uri: base64}));
    // const userDetails = yield select(selectUserDetails);

    // const customerParams = {
    //   "contact": {
    //     "phone": userDetails.phoneNumber,
    //     "email": userDetails.email
    //   },
    //   "firstName": userDetails.firstName,
    //   "middleName": userDetails.middleName,
    //   "lastName": userDetails.lastName,
    //   "customerSince": moment().format("YYYY-MM-DD"),
    // };
    // const customer = yield call(createMayaCustomer, customerParams);
    // const token = yield call(generateMayaToken, {card: action.payload.card });
    // const cardToken = yield call(linkGeneratedMayaTokenToCustomer, {
    //   customerId: customer.id,
    //   isDefault: true,
    //   paymentTokenId: token.paymentTokenId
    // });
    // const transaction = yield call(paymayaTransaction, customer.id, cardToken.cardTokenId, {
    //   totalAmount: {
    //     amount: action.payload.cost,
    //     currency: "PHP"
    //   },
    //   redirectUrl: {
    //     success: `${WEB_BASE_URL}/contactless/payment-success`,
    //     failure: `${WEB_BASE_URL}/contactless/payment-failed`,
    //   },
    //   requestReferenceNumber: document.id,
    //   metadata: {
    //     documentID: document.id,
    //     user: {
    //       id: userDetails.id,
    //       email: userDetails.email,
    //       firstName: userDetails.firstName,
    //       lastName: userDetails.lastName,
    //       mayaCustomerId: customer.id,
    //       mayaCardtokenId: cardToken.cardTokenId,
    //     },
    //     subMerchantRequestReferenceNumber: "",
    //   },
    // });

    // window.location.href = transaction.verificationUrl;
    
    yield put(signsecureActions.createLiveRequestSuccess(true));
    yield delay(1000);
    yield put(signsecureActions.createLiveRequestSuccess(false));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.createLiveRequestFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
  }
}

function* handleCreateContactlessRequest(action: {
  type: typeof signsecureActions.createContactlessRequest,
  payload: CreateLiveRequestValue,
}): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    const loginType = yield select(selectedAuthLoginType);
    const uri = yield call(affixSignature4Contactless,
      action.payload.uri,
      action.payload
    );
    const body = yield call(DocumentFormDataParser, 
      {...action.payload, loginType, uri: `data:application/pdf;base64,${uri}`}, ip);
    const result = yield call(signsecureCreateDocument, body, accessToken);
    yield put(signsecureActions.viewDocumentSuccess({...result.data, uri: `data:application/pdf;base64,${uri}`}));
    
    yield put(signsecureActions.createLiveRequestSuccess(true));
    yield delay(1000);
    yield put(signsecureActions.createLiveRequestSuccess(false));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.createLiveRequestFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
  }
}

function* handleContactlessPayment(action: {
  type: typeof signsecureActions.contactlessPaymentRequest;
  payload: any;
}): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    const userDetails = yield select(selectUserDetails);

    const customerParams = {
      "contact": {
        "phone": userDetails.phoneNumber,
        "email": userDetails.email
      },
      "firstName": userDetails.firstName,
      "middleName": userDetails.middleName,
      "lastName": userDetails.lastName,
      "customerSince": moment().format("YYYY-MM-DD"),
    };
    const customer = yield call(createMayaCustomer, customerParams);
    const token = yield call(generateMayaToken, {card: action.payload.card });
    const cardToken = yield call(linkGeneratedMayaTokenToCustomer, {
      customerId: customer.id,
      isDefault: true,
      paymentTokenId: token.paymentTokenId
    });
    const transaction = yield call(paymayaTransaction, customer.id, cardToken.cardTokenId, {
      totalAmount: {
        amount: action.payload.cost,
        currency: "PHP"
      },
      redirectUrl: {
        success: `${WEB_BASE_URL}/contactless/payment-success`,
        failure: `${WEB_BASE_URL}/contactless/payment-failed`,
      },
      requestReferenceNumber: document.id,
      metadata: {
        documentID: document.id,
        user: {
          id: userDetails.id,
          email: userDetails.email,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          mayaCustomerId: customer.id,
          mayaCardtokenId: cardToken.cardTokenId,
        },
        subMerchantRequestReferenceNumber: "",
      },
    });

    window.location.href = transaction.verificationUrl;
    yield put(signsecureActions.contactlessPaymentSuccess());
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(signsecureActions.createLiveRequestFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(signsecureActions.createLiveRequestFailed({}));
  }
}

function* handleViewDocument(): SagaIterator {
  try {
    const role = yield select(selectedAuthRole);
    const document = yield select(selectedViewDocument);
    const accessToken = yield select(selectedAuthAccessToken);
    if(_.isEmpty(document)){
      yield put(signsecureActions.viewDocumentFailed({}));
      return;
    }

    const result = yield call(
      signsecureViewDocument,
      document.id || document._id,
      accessToken
    );
    const base64 = yield call(
      signsecureDownloadDocument,
      document.id || document._id,
      accessToken
    );

    const notaryParty = result.data?.workflow?.parties?.find((party: any) => party.roleType === 'Notary');
    let notaryPublicDetails = {} as any;

    if(role === "notary" && !notaryParty?.status){
      notaryPublicDetails = yield call(
        fetchNotaryDetails,
        notaryParty?.id,
        accessToken
      );
    }
    
    yield put(signsecureActions.viewDocumentSuccess({ 
      ...result.data, 
      uri: base64, 
      notaryPublicDetails: notaryPublicDetails.data 
    }));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.viewDocumentFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.viewDocumentFailed({}));
  }
}

function* handleAffixSignature(): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    const loginType = yield select(selectedAuthLoginType);
    const accessToken = yield select(selectedAuthAccessToken);

    const base64 = yield call(submitOnlyAffixSignature,
      document.uri,
      document
    );

    const ip = yield call(getIpInfo);

    // Create a Blob from the Uint8Array
    const blob = new Blob([base64], { type: "application/pdf" });
    const signature = document.signature || {};
    // Append the Blob to the FormData
    const formData = new FormData();
    formData.append("file", blob, `${document.name}.pdf`);
    formData.append("ip", ip);
    formData.append("loginType", loginType);
    formData.append("signatureType", signature.signatureType);
    formData.append("signatureId", signature.signatureId);

    const result = yield call(
      signsecureSignDocument,
      document.id,
      formData,
      accessToken
    );

    const isCompleted = yield call(checkPartiesSigned, result.data);

    if(isCompleted){
      const uri = yield call(
        signsecureDownloadDocument,
        result.data.id,
        accessToken
      );
      const base64_complete = yield call(
        completeSignatureWithCreateManifest,
        uri,
        result.data
      );
      const newblob = new Blob([base64_complete], { type: "application/pdf" });
      const formDataComplete = new FormData();
      formDataComplete.append("file", newblob, `${result.data.name}.pdf`);
      const completed = yield call(
        signsecureCompleteDocument,
        result.data.id,
        formDataComplete,
        accessToken
      );

      const notarialBook = yield call(
        addDigitalNotarialBook,
        {
          fees: 0,
          documentId: result.data.id,
          placeOfNotarization: "Online",
          typeOfNotarialAct: result.data.notarialAct,
          competentEvidenceOfIdentity: result.data.requestType === 'Live' ? completed.data.governmentIssuedIdType : completed.data.governmentIssuedIdForPrincipal
        },
        accessToken
      );
      
      yield put(signsecureActions.affixSignatureSuccess(completed.data));
      return;
    }

    yield put(signsecureActions.affixSignatureSuccess(result.data));
  } catch (e: any) {
    console.log("Error", e);
    const message = e.message || e.error || "Something went wrong";
    yield put(signsecureActions.affixSignatureFailed({ message }));
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.affixSignatureFailed({}));
  }
}

function* handleRefreshLiveSessionLogic(action: any): SagaIterator {
  if (action.payload) {
    yield race([
      call(startPeriodicRefresh),
      take(signsecureActions.liveSessionRefreshStatus.type),  // Wait for the next toggle (stop)
    ]);
  }
}

function* startPeriodicRefresh() {
  try {
    while (true) {
      yield delay(15000); // 15 seconds
      yield call(refreshDocumentWorker);
    }
  } finally {
    console.log('Refresh task canceled');
  }
}

function* refreshDocumentWorker(): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    console.log("documentdocument", document)
    const accessToken = yield select(selectedAuthAccessToken);

    if(_.isEmpty(document)){
      return;
    }

    const result = yield call(
      signsecureViewDocument,
      document.id,
      accessToken
    );

    const base64 = yield call(
      signsecureDownloadDocument,
      document.id,
      accessToken
    );
    const signatures = document.workflow.signatures;
    const payload = { ...result.data, uri: base64 };
    payload.workflow.signatures = signatures;
    payload.notaryPublicDetails = document.notaryPublicDetails;

    yield put(signsecureActions.updateSignatures(payload));
  } catch (error) { /* empty */ }
}

// Watcher Saga
function* signsecureWatcherSaga(): SagaIterator {
  yield takeEvery(signsecureActions.createLiveRequest.type, handleCreateLiveRequest);
  yield takeEvery(signsecureActions.createContactlessRequest.type, handleCreateContactlessRequest);
  yield takeEvery(signsecureActions.viewDocumentRequest.type, handleViewDocument);
  yield takeEvery(signsecureActions.affixSignatureRequest.type, handleAffixSignature);
  yield takeLatest(signsecureActions.contactlessPaymentRequest.type, handleContactlessPayment);
  yield takeLatest(signsecureActions.liveSessionRefreshStatus.type, handleRefreshLiveSessionLogic);
}

export default signsecureWatcherSaga;
