import React, { useContext }  from 'react';
import DrawerItemHeader from './header/DrawerItemHeader';
import useStyles from './styles.css';
import { View } from 'react-native';
import DrawerItemBody from './body';
import { PluginProvider } from '../../../screens/live-request/review/provider/plugin';
import { CanvasProvider } from '../../../screens/live-request/review/provider/canvas';
import { DrawerContext } from '../../drawer-screens/DrawerProvider';

const DrawerBody = () => {
  const styles = useStyles();
  const { activeTab } = useContext(DrawerContext);

  return (
    <PluginProvider>
      <CanvasProvider>
        <View style={[styles.drawer_item_container]}>
          {["/contactless/payment-success", "/contactless/payment-failed", "PDF Viewer", "LiveRequest"].includes(activeTab) ? 
            null : <DrawerItemHeader /> }
          <DrawerItemBody />
        </View>
      </CanvasProvider>
    </PluginProvider>
  );
};

export default DrawerBody;
