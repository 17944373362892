import { SagaIterator } from '@redux-saga/core';
import { SignUpFormDataParser } from '@src/utils/formdata-helper';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

// Types
import * as Types from '../types';

// API
import {
  forgotpassword,
  login,
  loginFaceID,
  refreshToken,
  register,
  resetpassword,
} from '@src/utils/api';

// Slice
import { authActions, selectAuthLoginInput, selectedAuthRefresh } from '../slices/auth.slice';
import { forgotActions } from '../slices/forgot.slice';
import { signsecureActions } from '../slices/signsecure.slice';
import { signupActions } from '../slices/signup.slice';
import { dashboardActions } from '../slices/dashboard.slice';

function* handleSignin(): SagaIterator {
  try {
    const loginInput = yield select(selectAuthLoginInput);
    const user = yield call(login, loginInput);

    yield put(authActions.loginSuccess({...user.data, loginType: "Login via Email"}));
  } catch (e: any) {
    console.log('Error', e);
    const message = e.message || e.error || 'Somthing went wrong';
    const failedMessage = message.replace(/^HttpException:\s*/, '');

    yield put(authActions.loginFailure({ message: failedMessage }));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleFaceIDSignin(): SagaIterator {
  try {
    const { email } = yield select(selectAuthLoginInput); 
    const user = yield call(loginFaceID, {email});
    const param ={ 
      id: user.data?.id,
      role: user.data?.role,
      userId: user.data?.id,
      email: user.data?.email,
      givenName: user.data?.givenName,
      lastName: user.data?.lastName,
      accessToken: user.data?.accessToken,
      refreshToken: user.data?.refreshToken,
      loginType: "Login via Face Recognition"
    };
    yield put(authActions.loginSuccess(param));
  } catch (error: any) {
    console.log("error", error, typeof error.error);
    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage }));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleSignup(action: {
  type: typeof signupActions.signupRequest;
  payload: Types.SignupInput;
}): SagaIterator {
  try {
    const body = yield call(SignUpFormDataParser, action.payload);

    const user = yield call(register, body);

    yield put(signupActions.signupSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || 'Something went wrong';

    const failedMessage = (
      typeof message === 'object' ? message[0] : message
    ).replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(signupActions.signupFailure({ message: failedMessage }));
    yield delay(1000);
    yield put(signupActions.signupFailure({}));
  }
}

function* handleForgotpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const user = yield call(forgotpassword, action.payload);

    yield put(forgotActions.forgotSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';
    const failedMessage = message.replace(/^HttpException:\s*/, '');

    yield put(forgotActions.forgotFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(forgotActions.forgotFailed({}));
  }
}

function* handleResetpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.ResetPasswordInput;
}): SagaIterator {
  try {
    const user = yield call(resetpassword, action.payload);

    yield put(forgotActions.resetSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';
    const failedMessage = message.replace(/^HttpException:\s*/, '');

    yield put(forgotActions.resetFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(forgotActions.resetFailed({}));
  }
}

function* refreshTokenWorker(): SagaIterator {
  try {
    // Get session token details save in reducer
    const session = yield select(selectedAuthRefresh);

    // Make an API call to refresh the token
    const result = yield call(refreshToken, session);

    // Dispatch an action to update the token in the Redux store
    yield put(authActions.refreshTokenUpdate(result.data.accessToken)); // Replace 'UPDATE_TOKEN' with your actual action type

    // Delay for 1 hour (in milliseconds) before triggering the token refresh again
    yield delay(60 * 30 * 1000);

    // Restart the token refresh process by calling the same worker saga recursively
    yield call(refreshTokenWorker);
  } catch (error) {}
}

function* handleLogout(): SagaIterator {
  yield put(signsecureActions.resetSignSecure()); // Reset signsecure states
  yield put(dashboardActions.activeDrawer("Dashboard")); // Reset signsecure states
  
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.loginRequest.type, handleSignin);
  yield takeEvery(authActions.faceIDloginRequest.type, handleFaceIDSignin);
  yield takeEvery(signupActions.signupRequest.type, handleSignup);
  yield takeEvery(forgotActions.forgotRequest.type, handleForgotpassword);
  yield takeEvery(forgotActions.resetRequest.type, handleResetpassword);
  yield takeEvery(authActions.refreshTokenRequest.type, refreshTokenWorker);
  yield takeEvery(authActions.logout.type, handleLogout);
}

export default authWatcherSaga;
