/* eslint-disable react/no-unstable-nested-components */
import Icon from '@src/components/icon';
import React from 'react';
import { Pressable, Text, View, ViewStyle } from 'react-native';
import MenuDropdown, { MeasureValue } from './Menu';
import useStyles from './styles.css';

type DropdownProps = {
  name?: string;
  value?: string;
  color?: string;
  iconSize?: number;
  iconDisabled?: boolean;
  options?: any[];
  style?: ViewStyle;
  renderItem?: any;
  disabled?: boolean;
  onSelected: (item: any) => void;
  renderBase?: any;
};

function Dropdown(props: DropdownProps) {
  const styles = useStyles();
  const pressableRef = React.useRef<any>(null);
  const [isFocus, setFocus] = React.useState<boolean>(false);
  const [measure, setMesasure] = React.useState<MeasureValue>(
    {} as MeasureValue
  );

  const RenderBase = () => {
    if (typeof props.renderBase === 'function') {
      return props.renderBase({
        value: props.value,
        name: props.name,
        iconSize: props.iconSize,
      });
    }

    return (
      <>
        <View style={[styles.v_circle, { backgroundColor: props.color }]} />
        <Text selectable={false} style={styles.t_label}>
          {props.value}
        </Text>
        {props.iconDisabled ? null : <Icon name={props.name} size={props.iconSize} color={props.color} />}
      </>
    );
  };

  React.useEffect(() => {
    if (pressableRef.current) {
      pressableRef.current?.measure(
        (
          fx: any,
          fy: any,
          width: number,
          height: any,
          px: number,
          py: number
        ) => {
          setMesasure({
            x: px,
            y: py,
            width,
            height,
          });
        }
      );
    }
  }, [pressableRef.current, isFocus]);

  if (typeof props.renderBase === 'function') {
    return (
      <View ref={pressableRef} style={[props.style]}>
        {props.renderBase({
          value: props.value,
          name: props.name,
          iconSize: props.iconSize,
          onPress: () => setFocus(true),
        })}
        <MenuDropdown
          visible={isFocus}
          measures={measure}
          value={props.value}
          options={props.options}
          renderItem={props.renderItem}
          onSelected={props.onSelected}
          onClose={() => setFocus(false)}
        />
      </View>
    );
  }

  return (
    <Pressable
      ref={pressableRef}
      disabled={props.disabled}
      onPress={() => setFocus(true)}
      style={[styles.container, props.style]}
    >
      {RenderBase()}
      <MenuDropdown
        visible={isFocus}
        measures={measure}
        value={props.value}
        options={props.options}
        renderItem={props.renderItem}
        onSelected={props.onSelected}
        onClose={() => setFocus(false)}
      />
    </Pressable>
  );
}

export default Dropdown;
