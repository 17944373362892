import { useRoute } from '@react-navigation/native';
import Loading from '@src/components/loading';
import { useAppSelector } from '@src/ducks/ducksHook';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { HeaderContext } from './provider/header';
import useStyles from './styles.css';
import { selectedViewDocument } from '@src/ducks/slices/signsecure.slice';

// SCREENS
const Identification = React.lazy(() => import('./identification'));
const SubmitDocuments = React.lazy(() => import('./submit-documents'));
const AddParticipants = React.lazy(() => import('./add-participants'));
const VideoSession = React.lazy(() => import('./video-session'));
const AddFields = React.lazy(() => import('./add-fields'));
const ReviewDetails = React.lazy(() => import('./review-details'));
const WaitingApproval = React.lazy(() => import('./waiting-approval'));
const Review = React.lazy(() => import('./review'));
const ChooseNotaryPublic = React.lazy(() => import('./choose-notary-public'));
const Screens = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const document = useAppSelector(selectedViewDocument);  
  const routeName: any = route.name; // Get Nested Route Name
  const initialActiveIndex = document?.workflow?.status === 'FOR PAYMENT' ? 6 : 0;
  const { activeIndex, onSetCurrentPage } = React.useContext(HeaderContext);

  useEffect(() => {
    // Update activeIndexState when document status changes
    if (document?.workflow?.status === 'FOR PAYMENT') {
      // onSetCurrentPage(6)
      onSetCurrentPage(initialActiveIndex)
    }
  }, [document?.workflow?.status]);

  // const role = useAppSelector(selectedAuthRole);

  const components = React.useMemo(() => {
    // if (role === 'principal') {
    switch (activeIndex) {
      case 0:
        return <Identification />;
      case 1:
        return <SubmitDocuments />;
      case 2:
        return <AddParticipants />;
      case 3:
        return <ChooseNotaryPublic />;
      case 4:
        return <AddFields />;
      case 5:
        return <ReviewDetails />;
      case 6:
        return <Review />;
      case 7:
        return <WaitingApproval />;
    }
    // } else {
      // switch (activeIndex) {
      //   case 0:
      //     return <VideoSession />;
      //   case 1:
      //     return <VideoSession />;
      //   case 2:
      //     return <Review />;
      // }
    // }
  }, [activeIndex]);

  // React.useEffect(() => {
  //   if (document?.workflow?.status === 'FOR PAYMENT') {
  //     const timeoutId = setTimeout(() => {
  //       onSetCurrentPage(6);
  //     }, 2000); // 5000 milliseconds = 5 seconds
  
  //     // Cleanup function to clear the timeout if the component unmounts or dependencies change
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [document]);


  return (
    <View style={styles.scrollview} >
      <React.Suspense fallback={<Loading />}>{components}</React.Suspense>
    </View>
  );
};

export default React.memo(Screens);
