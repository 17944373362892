import { useDrawerContext } from '@src/screens/drawer-screens/DrawerProvider';
import React, { createContext, useCallback, useContext, useState } from 'react';
import * as Types from './types';

interface ContextValue {
  activeIndex: number;
  onBack: () => void;
  onNext: () => void;
  onSetCurrentPage: (value: number) => void;
}

const HeaderContext = createContext<ContextValue>({} as ContextValue);

const HeaderProvider = ({ children }: Types.ProviderProps) => {
  const { onActiveTab } = useDrawerContext();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onBack = useCallback(() => {
    if (activeIndex === 0 || activeIndex === 5 || activeIndex === 6) {
      onActiveTab('Dashboard')
      return;
    }
    setActiveIndex(activeIndex - 1);
  }, [activeIndex]);

  const onNext = useCallback(() => {
    setActiveIndex(activeIndex + 1);
  }, [activeIndex]);

  const onSetCurrentPage = useCallback(
    (value: number) => {
      setActiveIndex(value);
    },
    [activeIndex]
  );

  const value = {
    activeIndex,
    onBack,
    onNext,
    onSetCurrentPage,
  };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  return useContext(HeaderContext);
};

export { HeaderContext, HeaderProvider };
