import React from 'react';
import { Text } from 'react-native';
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { useDrawerContext } from '../DrawerProvider';
import { useDrawerComponentContext } from './provider';
import useStyles from './styles.css';

const CopyRightContent = () => {
  const styles = useStyles();
  const { onActiveTab } = useDrawerContext();
  const { animated } = useDrawerComponentContext();

  const animateStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      animated.value,
      [110, 200, 360],
      [0, 0, 1],
      Extrapolation.CLAMP,
    );

    return {
      opacity,
      display: opacity === 1 ? "flex" : "none"
    };
  }, []);

  return (
    <Animated.View style={[styles.drawer_content_footer, animateStyle]}>
      <Text style={styles.copyRightStyle}>
        <Text style={styles.copyRightBtnStyle} onPress={() => onActiveTab('Terms & Conditions')}>Terms & Conditions</Text> |
        <Text style={styles.copyRightBtnStyle} onPress={() => onActiveTab('Privacy Policy')}> Privacy Policy</Text>
        {'\n© 2024 '}
        <Text style={styles.copyRightBtnStyle}>UNAWA</Text>. All rights reserved<br/>Version 1
      </Text>
    </Animated.View>
  );
};

export default CopyRightContent;
